<template>
    <v-container fluid style="padding-bottom: 100px;">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                     <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col>
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete 
                                    dense
                                    solo
                                    class="ma-0 pa-2 border-12"
                                    hide-details=true 
                                    v-model="entity" 
                                    :items="entities"
                                    item-value="Entity" 
                                    item-text="entity" 
                                    label="Entity ID"
                                    prepend-icon="mdi-garage" 
                                    @change="(event) => getRegion(event)"
                                    :disabled="disabled_entity"
                                >
                                </v-autocomplete>
                                
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete 
                                    dense
                                    solo
                                    class="ma-0 pa-2 border-12"
                                    hide-details=true 
                                    v-model="region" 
                                    :items="regions" 
                                    item-value="region" 
                                    item-text="region" 
                                    label="Region"
                                    prepend-icon="mdi-store" 
                                    @change="(event) => getOffice(event)"    
                                    clearable 
                                    :disabled="disabled_region"
                                >
                                </v-autocomplete>
                                
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete 
                                    dense
                                    solo
                                    class="ma-0 pa-2 border-12"
                                    hide-details=true 
                                    v-model="office" 
                                    :items="offices" 
                                    default="" 
                                    item-value="office_id" 
                                    :item-text="item => item.office_id +' - '+ item.office"
                                    label="Office ID" 
                                    clearable 
                                    :disabled="disabled_office"
                                    prepend-icon="mdi-home-assistant"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu ref="modal" 
                                v-model="modal" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        solo 
                                        v-model="date_from" 
                                        label="Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 pa-2 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="date_from" 
                                        type="month" 
                                        no-title @input="modal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>    
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-2 border-12" style="padding: 19px;" small color="primary" elevation="2" @click="submit()">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col class="col-12" sm="6" md="6">
                <v-card outlined>
                    <v-card-title>
                        Summary Report
                    </v-card-title>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="6">
                                <v-card class="rounded-l" outlined>
                                    <v-card-text class="p-4">
                                        <v-row>
                                            <v-col cols="12">
                                                <div class="d-flex">
                                                    <!-- <v-icon color="#2083b4">
                                                        mdi-currency-usd
                                                    </v-icon> -->
                                                    <div class="ml-8">
                                                        <p class="pb-1 mb-0">Forecast</p>
                                                        <h4 style="font-weight:600" class="mt-0 pt-0">
                                                        {{forecast}}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="6">
                                <v-card class="rounded-l" outlined>
                                    <v-card-text class="p-4">
                                        <v-row>
                                            <v-col cols="12">
                                                <div class="d-flex">
                                                    <!-- <v-icon color="#2083b4">
                                                        mdi-currency-usd
                                                    </v-icon> -->
                                                    <div class="ml-8">
                                                        <p class="pb-1 mb-0">Realisasi</p>
                                                        <h4 style="font-weight:600" class="mt-0 pt-0">
                                                        {{realisasi}}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-card outlined class="mt-2" height="300">
                    <v-card-title>
                        Summary Brand ID
                    </v-card-title>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="6" v-for="(summary_brand, key) in summary_brands" :key="key">
                                <v-card class="rounded-l" outlined>
                                    <v-card-title class="p-2">
                                        {{ summary_brand.brand_id }}
                                    </v-card-title>
                                    <v-card-text class="p-4">
                                        <v-row>
                                            <v-col cols="12">
                                                <div class="d-flex">
                                                    <!-- <v-icon color="#2083b4">
                                                        mdi-currency-usd
                                                    </v-icon> -->
                                                    <div class="ml-4">
                                                        <p class="pb-1 mb-0">Forecast</p>
                                                        <h4 style="font-weight:600" class="mt-0 pt-0">
                                                        {{summary_brand.forecast}}
                                                        </h4>
                                                    </div>
                                                    <v-spacer></v-spacer>
                                                    <!-- <v-icon color="#2083b4">
                                                        mdi-currency-usd
                                                    </v-icon> -->
                                                    <div class="ml-4">
                                                        <p class="pb-1 mb-0">Realisasi</p>
                                                        <h4 style="font-weight:600" class="mt-0 pt-0">
                                                        {{summary_brand.realisasi}}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-card outlined class="mt-2">
                    <v-card-title>
                        Detail
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="header_summary_brand_region_offices"
                                    :items="summary_brand_region_offices"
                                    :loading="$store.state.overlay"
                                    :item-class="tr_datatable"
                                    :items-per-page="20"
                                    fixed-header
                                    height="300"
                                    :divider="true"
                                    :light="true"
                                >        
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-12" sm="6" md="6">
                <v-card outlined>
                    <v-card-title>
                        Summary Region Per-Brand
                    </v-card-title>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="header_summary_brand_regions"
                                    :items="summary_brand_regions"
                                    :loading="$store.state.overlay"
                                    :item-class="tr_datatable"
                                    :items-per-page="20"
                                    fixed-header
                                    height="782"
                                    :divider="true"
                                    :light="true"
                                >        
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {filter} from "../../../backend-api/filter/index"
import {backendApi} from "../../../backend-api/backend-api-sr"
import {forecast} from "../../../backend-api/viva/forecast"

export default {
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search:'',
            breadcumbs: [
                {
                    text: 'Viva',
                    disabled: false,
                    href: '/admin/viva',
                },
                {
                    text: 'Forecast',
                    disabled: false,
                    href: '/admin/viva/dashboard',
                },
                {
                    text: 'Forecast vs Realisasi by Barang Jadi',
                    disabled: true,
                }
            ],
            entity: '',
            entities: [],
            disabled_entity: false,
            region: '',
            regions: [],
            disabled_region: false,
            office: '',
            offices: [],
            disabled_office: false,
            modal: false,
            modal_to: false, 
            date_from:'',
            date_to:'',
            group_id: '',
            envUserData:[],
            summary_brands: [],
            forecast: 0,
            realisasi: 0,
            summary_brand_regions: [],
            header_summary_brand_regions: [
                {
                text: 'Region',
                align: 'start',
                value: 'region', 
                align:'left'},
                { text: 'Brand ID', value: 'brand_id' , align:'left'},
                { text: 'BarangJadiID', value: 'barangjadiid' , align:'left'},
                { text: 'Nama Barang', value: 'NamaBarang' , align:'left'},
                { text: 'forecast', value: 'forecast' , align:'right'},
                { text: 'Realisasi', value: 'realisasi' , align:'right'},
            ],
            summary_brand_region_offices: [],
            header_summary_brand_region_offices:[
            {
                text: 'Region',
                align: 'start',
                value: 'region', 
                align:'left'},
                { text: 'Office ID', value: 'office_id' , align:'left'},
                { text: 'Brand ID', value: 'brand_id' , align:'left'},
                { text: 'BarangJadiID', value: 'barangjadiid' , align:'left'},
                { text: 'Nama Barang', value: 'NamaBarang' , align:'left'},
                { text: 'forecast', value: 'forecast' , align:'right'},
                { text: 'Realisasi', value: 'realisasi' , align:'right'},
            ]
        }
    },
    async mounted(){
        // this.disabled_entity = true
        // this.disabled_region = true
        // this.disabled_office = true

        // await this.getEntity()
        // await this.getRegion()
        // await this.getOffice()
        
        // await this.getEnvConf()
        await this.getEnvConfTwo()
        
    },
    methods: {
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        async getEnvConf(){
            let varEntity = false
            let varRegion = false
            let varOffice = false
            let ent = []
            let reg = []
            let off = []
            const respData = await backendApi.fetchCore(`/api/get_group_user?entity_id=VIVA&appl_id=WEBVIVA`, null, false, false, false)
            if (respData.status === 200) {
                this.group_id = respData.data.data

                let data = {
                    entity_id : 'VIVA',
                    appl_id : 'WEBVIVA',
                    var_id : ['WHOAMI', 'BRANCH', 'REGION'],
                    group_id : this.group_id
                }
                
                const respDataGroup = await forecast.fetchEnvConfGroup(data);
                // const respDataGroup = await filter.fetchEnvconf(`?entity_id=VIVA&appl_id=WEBVIVA&group_id=${this.group_id}`);
                // console.log(respDataGroup.data.data);
                if (respDataGroup.data.data.length > 0) {
                    // this.entity = respDataGroup.data.data[0].var_value
                    // this.disabled_entity = true

                    
                    for (let i = 0; i < respDataGroup.data.data.length; i++) {
                        console.log(respDataGroup.data.data[i]['var_id'])
                        if (respDataGroup.data.data[i]['var_id'] === 'WHOAMI') {
                            this.entity = respDataGroup.data.data[i]['var_value']
                            if(this.entity.includes(",")){
                                // console.log(this.entiti)
                                // this.entities = this.entity.split(",");
                                ent = this.entity.split(",")
                                this.entity = this.entities[0]
                            } else {
                                this.disabled_entity = true
                                varEntity = true
                            }
                        }
                        if (respDataGroup.data.data[i]['var_id'] === 'REGION') {
                            this.region = respDataGroup.data.data[i]['var_value']
                            this.disabled_region = true
                            varRegion = true
                        }
                        if (respDataGroup.data.data[i]['var_id'] === 'BRANCH') {
                            this.office = respDataGroup.data.data[i]['var_value']
                            this.disabled_office = true
                            varOffice = true
                        }
                    }
                }

                let dataUser = {
                    entity_id : 'VIVA',
                    appl_id : 'WEBVIVA',
                    var_id : ['WHOAMI', 'BRANCH', 'REGION'],
                }
                this.envUserData = await filter.fetchEnvConfUser(dataUser);
                if (this.envUserData.data.data.length > 0) {
                    for (let i = 0; i < this.envUserData.data.data.length; i++) {
                        if (this.envUserData.data.data[i]['var_id'] === 'WHOAMI') {
                            this.entity = this.envUserData.data.data[i]['var_value']
                            if(this.entity.includes(",")){
                                // console.log(this.entiti)
                                this.entities = this.entity.split(",");
                                this.entity = this.entities[0]
                            } else {
                                this.disabled_entity = true
                                varEntity = true
                            }
                        }
                        if (this.envUserData.data.data[i]['var_id'] === 'REGION') {
                            this.region = this.envUserData.data.data[i]['var_value']
                            this.disabled_region = true
                            varRegion = true
                        }
                        if (this.envUserData.data.data[i]['var_id'] === 'BRANCH') {
                            this.office = this.envUserData.data.data[i]['var_value']
                            this.disabled_office = true
                            varOffice = true
                        }
                        
                    }
                }
            }
            if (!varEntity){
                this.disabled_entity = false
            }
            if (!varRegion){
                this.disabled_region = false
            }
            if (!varOffice){
                this.disabled_office = false
            }
            // lakukan get filter awal
            let ppl = {
                entity_id_in : this.entities,
                region : 'WEBVIVA',
                office : ['WHOAMI', 'BRANCH', 'REGION'],
                group_id : this.group_id
            }
        },
        async getEnvConfTwo(){
            let varEntity = false
            let varRegion = false
            let varOffice = false
            let ent = []
            let reg = ''
            let off = ''
            const respData = await backendApi.fetchCore(`/api/get_group_user?entity_id=VIVA&appl_id=WEBVIVA`, null, false, false, false)
            if (respData.status === 200) {
                this.group_id = respData.data.data
                let data = {
                    entity_id : 'VIVA',
                    appl_id : 'WEBVIVA',
                    var_id : ['WHOAMI', 'BRANCH', 'REGION'],
                    group_id : this.group_id
                }
                let dataUser = {
                    entity_id : 'VIVA',
                    appl_id : 'WEBVIVA',
                    var_id : ['WHOAMI', 'BRANCH', 'REGION'],
                }
                const respDataGroup = await forecast.fetchEnvConfGroup(data);
                const respUserData = await filter.fetchEnvConfUser(dataUser);
                console.log(respDataGroup.data.data)
                console.log(respUserData.data.data)
                if (respDataGroup.data.data.length > 0) {
                    for (let i = 0; i < respDataGroup.data.data.length; i++) {
                        if (respDataGroup.data.data[i]['var_id'] === 'WHOAMI') {
                            let d = respDataGroup.data.data[i]['var_value']
                            if(d.includes(",")){
                                ent = d.split(",")
                            } else {
                                ent = [d]
                            }
                        }
                        if (respDataGroup.data.data[i]['var_id'] === 'REGION') {
                            reg = respDataGroup.data.data[i]['var_value']
                        }
                        if (respDataGroup.data.data[i]['var_id'] === 'BRANCH') {
                            off = respDataGroup.data.data[i]['var_value']
                        }
                    }
                }
                if (respUserData.data.data.length > 0) {
                    for (let i = 0; i < respUserData.data.data.length; i++) {
                        if (respUserData.data.data[i]['var_id'] === 'WHOAMI') {
                            let d = respUserData.data.data[i]['var_value']
                            if(d.includes(",")){
                                ent = d.split(",")
                            } else {
                                ent = [d]
                            }
                        }
                        if (respUserData.data.data[i]['var_id'] === 'REGION') {
                            reg = respUserData.data.data[i]['var_value']
                        }
                        if (respUserData.data.data[i]['var_id'] === 'BRANCH') {
                            off = respUserData.data.data[i]['var_value']
                        }
                    }
                }
                console.log(ent)
                let bodyData = {
                    "entity_id_in": ent,
                    "region": reg,
                    "office": off
                }
                await this.getAllFilter(bodyData)
            }
        },
        async getAllFilter(bodyData){
            var respData = await forecast.getFilterTwo(bodyData, null, false, false, false)
            if (respData.status === 200) {
                this.entities = respData.data.data.entity
                this.entity = this.entities[0].entity
                if(this.entities.length === 1){
                    this.disabled_entity = true
                }
                this.regions = respData.data.data.region
                if(this.regions.length === 1){
                    this.disabled_region = true
                }
                // this.region = this.regions[0]
                this.offices = respData.data.data.office
                if(this.offices.length === 1){
                    this.disabled_office = true
                }
                // this.office = this.offices[0]
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Connection problem, please refresh. If problem persist contact IT.',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                return false
            }
        },
        async getEntity(){
            var respData = await filter.fetchforecastfilter(`?entity_id=${this.entity ? this.entity : ''}`, null, false, false, false)
            if (respData.status === 200) {
                this.entities = respData.data.data.Entity
            }
        },
        async getRegion(event){
            if (event === '' || event === null) {
                this.region = ''
            } else {
                var respData = await filter.fetchforecastfilter(`?entity_id=${this.entity ? this.entity : ''}&region=${this.region ? this.region : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    this.regions = respData.data.data.Region
                }
            }
        },
        async getOffice(event){
            if (event === '' || event === null) {
                this.office = ''
            } else {
                var respData = await filter.fetchforecastfilter(`?entity_id=${this.entity ? this.entity : ''}&region=${this.region ? this.region : ''}&office=${this.office ? this.office : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    this.offices = respData.data.data.Office
                }
            }
        },
        clear(){
            
        },
        async submit(){
            this.$store.dispatch('setOverlay', true)

            if (this.entity == '' || this.entity === null || this.date_from == '' || this.date_from === null) {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please Insert Complate Entity ID and Period !',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                return false
            } else {
                await this.searchSummaryReport()
                await this.searchSummaryBrand()
                await this.searchSummaryBrandRegion()
                await this.searchSummaryBrandRegionOffice()
                this.$store.dispatch('setOverlay', false)
            }
        },
        async searchSummaryReport(){
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 8).replace(/-/g,"") : ''
            var respData = await filter.fetchforecastdua(`?entity_id=${this.entity ? this.entity : ''}&office_id=${this.office ? this.office : ""}&region=${this.region ? this.region : ""}&period_start=${start_date ? start_date : ""}`, null, false, false, false)
            if (respData.status === 200) {
                this.forecast = respData.data.data ? respData.data.data[0].forecast : 0
                this.realisasi = respData.data.data ? respData.data.data[0].realisasi : 0
            }
        },
        async searchSummaryBrand(){
            this.summary_brands = []
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 8).replace(/-/g,"") : ''
            var respData = await filter.fetchsum(`?entity_id=${this.entity ? this.entity : ''}&office_id=${this.office ? this.office : ""}&region=${this.region ? this.region : ""}&period_start=${start_date ? start_date : ""}`, null, false, false, false)
            if (respData.status === 200) {
                this.summary_brands = respData.data.data
            }
        },
        async searchSummaryBrandRegion(){
            this.summary_brand_regions = []
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 8).replace(/-/g,"") : ''
            var respData = await filter.getSummaryRegionBrand(`?entity_id=${this.entity ? this.entity : ''}&office_id=${this.office ? this.office : ""}&region=${this.region ? this.region : ""}&period_start=${start_date ? start_date : ""}`, null, false, false, false)
            if (respData.status === 200) {
                this.summary_brand_regions = respData.data.data
                console.log(this.summary_brand_regions);
            }
        },
        async searchSummaryBrandRegionOffice(){
            this.summary_brand_region_offices = []
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 8).replace(/-/g,"") : ''
            var respData = await filter.getSummaryRegionBrandOffice(`?entity_id=${this.entity ? this.entity : ''}&office_id=${this.office ? this.office : ""}&region=${this.region ? this.region : ""}&period_start=${start_date ? start_date : ""}`, null, false, false, false)
            if (respData.status === 200) {
                this.summary_brand_region_offices = respData.data.data
                console.log(this.summary_brand_region_offices);
            }
        }
    },
    
}
</script>
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
}
.tr_datatable{
  background-color: #F5F7F8 !important;
}
.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
.v-card {
  flex-grow: 1;
  overflow: auto;
}
</style>