import {backendApi} from "../backend-api-sr"
//import {backendApi} from "../../backend-api-sr"

export const forecast = (() =>{
    const fetchEnvConfGroup = ( async (reqBody) => {
        var url = '/api/viva/forecast/envConfGroupPost'
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const fetchEnvConfUser = ( async (reqBody) => {
        var url = '/api/viva/forecast/envConfUser'
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const getFilterTwo = ( async (reqBody) => {
        var url = '/api/viva/forecast/getFilterTwo'
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const entityOnChange = ( async (reqBody) => {
        var url = '/api/viva/forecast/entityOnChange'
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const regionOnChange = ( async (reqBody) => {
        var url = '/api/viva/forecast/regionOnChange'
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    return {fetchEnvConfGroup, fetchEnvConfUser, getFilterTwo, entityOnChange, regionOnChange};

})()